import * as React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { IconChevronRight } from '../Icons'
import CheckoutPayment from './CheckoutPayment'
import CheckoutDetails from './CheckoutDetails'
import CheckoutSummary from './CheckoutSummary'
import CartSummary from './CartSummary'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK)
import { changeStatus, CHECKOUT_STATUS_FAILED } from '../../state/cart'
import { updateOrderId } from '../../state/checkout'

const CheckoutProcess = ({ step, setStep, location }) => {
  const dispatch = useDispatch()
  const checkout = useSelector(state => state.checkout)
  const cart = useSelector(state => state.cart)
  const highlight = useSelector(state => state.cart.status_highlight)
  useEffect(() => {
    const t = setTimeout(() => {
      window.scrollTo(0, 0)
    }, 50)
    return () => {
      clearTimeout(t)
    }
  }, [step])
  React.useEffect(() => {
    // console.log('*** Location', location.hash)
    if (location) {
      const hash = location.hash
      if (hash === '#shipping' || hash === '' || hash === '#') {
        setStep(1)
      } else if (hash === '#payment') {
        setStep(2)
      } else if (hash === '#summary') {
        setStep(3)
      }
    }
  }, [location.hash, setStep])

  const setUrlState = React.useCallback(
    _step => {
      // check if step is a function and call it
      if (typeof _step === 'function') {
        _step = _step(step)
      }
      // console.log('Set step', _step)
      if (_step === 1) {
        window.location = '/checkout/#shipping'
      }
      if (_step === 2) {
        window.location = '/checkout/#payment'
      }
      if (_step === 3) {
        window.location = '/checkout/#summary'
      }
      setStep(_step)
    },
    [window?.history, step, setStep]
  )
  //
  // React.useEffect(() => {
  //   if (step === 1) {
  //     window.history.pushState({}, '', '/checkout/#shipping')
  //   }
  //   if (step === 2) {
  //     window.history.pushState({}, '', '/checkout/#payment')
  //   }
  //   if (step === 3) {
  //     window.history.pushState({}, '', '/checkout/#summary')
  //   }
  // }, [step])
  const handlerError = async (res, err) => {
    let errors = []
    let message = 'Cannot process order at this time. Please try again later.'
    if (res && res.status !== 200) {
      try {
        const body = await res.json()
        message = body.message
        if (body.errors) {
          errors = body.errors
          message +=
            '\r\n\r\n' +
            body.errors
              .map(e => {
                if (typeof e === 'string') return e
                if (Object.hasOwnProperty.bind(e)('message')) return e.message
                return JSON.stringify(e)
              })
              .join('\n')
        }
      } catch {
        message = 'Cannot process order at this time. Please try again later.'
      }
    } else if (err) {
      console.log('initiateCheckout error', err)
      message = 'Cannot process order at this time. Please try again later. ' + err.message
    }
    dispatch(
      changeStatus({
        status: CHECKOUT_STATUS_FAILED,
        message,
        errors,
        show: true,
        highlight: 'checkout',
      })
    )
  }
  React.useEffect(() => {
    if (step !== 3) return
    fetch('/api/init-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        checkout,
        cart: cart.products.map(prod => ({ qty: prod.qty, sku: prod.sku })),
        _qs: window?.qs,
        _initial_page: window?._initial_page,
      }),
    })
      .then(async res => {
        if (res.status !== 200) {
          return handlerError(res)
        }
        const data = await res.json()
        dispatch(updateOrderId(data))
      })
      .catch(err => handlerError(null, err))
  }, [dispatch, step])

  const options = {
    // clientSecret: checkout.payment_method === 'CreditCard' ? checkout.order_url : '',
    // appearance: {
    //   /* your appearance settings */
    // },
    // Add billing details with the ZIP code
    defaultValues: {
      billingDetails: {
        address: {
          postal_code: checkout.billing_zip,
        },
      },
    },
  }
  if (checkout.payment_method === 'CreditCard' && (checkout.order_url||'').toString().length > 5) {
    options.clientSecret = checkout.order_url
  }

  return (
    <Elements stripe={stripePromise} options={options} key={options.clientSecret || 'initializing'}>
      <section className="flex flex-col md:flex-grow px-4 md:px-0">
        <hr className="hidden md:block" />
        <div className="hidden md:flex w-full max-w-[1076px] mx-auto flex-row items-center gap-3 md:gap-5 justify-center md:justify-start text-sm md:text-base py-2 overflow-x-hidden px-4 ">
          <div className={' ' + (step === 1 ? ' text-red-700' : '')}>DETAILS</div>
          <div>
            <IconChevronRight />
          </div>
          <div className={' ' + (step === 2 ? ' text-red-700' : '')}>PAYMENT</div>
          <div>
            <IconChevronRight />
          </div>
          <div className={' ' + (step === 3 ? ' text-red-700' : '')}>SUMMARY</div>
        </div>
        <hr />

        <div className="flex justify-center flex-grow w-full">
          <div className="w-full flex flex-col justify-center items-center md:flex-row md:items-start gap-2 md:gap-0 lg:space-x-10">
            <div
              className={
                'flex my-4 w-full max-w-[580px] md:order-1 order-2 flex-col md:sticky top-3 duration-300 ease-in-out md:px-4' +
                (step === 3 ? ' order-last md:order-none' : '') +
                (highlight === 'form' ? ' bg-white z-10 ' : ' ')
              }
            >
              {step === 1 ? <CheckoutDetails setStep={setUrlState} /> : <></>}
              {step === 2 ? <CheckoutPayment setStep={setUrlState} /> : <></>}
              {step === 3 ? <CheckoutSummary setStep={setUrlState} /> : <></>}
            </div>
            <div className="hidden md:block order-1 h-full border-r w-4 mr-2 bg-gradient-to-l from-slate-100 to-transparent"></div>

            <CartSummary />
          </div>
        </div>
      </section>
    </Elements>
  )
}
CheckoutProcess.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  location: PropTypes.object,
}

export default CheckoutProcess
