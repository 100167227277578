import * as React from 'react'
import { useCallback } from 'react'
import { Link } from 'gatsby'
import { GnsLogo, IconChatDL, IconPhoneDL, IconMailDL } from '../Icons'
import { LiveChatWidget, useWidgetCustomerData } from '@livechat/widget-react'
import { useSelector } from 'react-redux'
import { CHECKOUT_STATUS_FAILED } from '../../state/cart'
import { TrackEvent, TrackUser } from '../../helpers/TrackingHelper'
import PropTypes from 'prop-types'

const CheckOutHeader = ({ step }) => {
  const [chat, setChat] = React.useState(false)
  const customerData = useWidgetCustomerData()
  const cartStatus = useSelector(state => state.cart.status)

  const openChat = useCallback(
    e => {
      e.preventDefault()
      setChat(true)
      if (window?.LC_API?.open_chat_window) {
        window.LC_API.open_chat_window()
      }
    },
    [setChat]
  )
  const onFormSubmitted = data => {
    var full_name = customerData.name
    var email = customerData.email 
    TrackUser(email, { name: full_name, full_name })
    const eventData = {
      ...customerData,
      email: email,
      name: full_name,
      full_name,
      '$email': email,
      '$name': full_name,
      event_type: data.type ,
      event_category: 'Live Chat'
    }
    if (data.type === 'prechat') {
      TrackEvent('Started Live Chat', eventData, null)
    } else if (data.type === 'postchat') {
      TrackEvent('Live Chat Postchat Form Submitted', eventData)
    } else if (data.type === 'ticket') {
      TrackEvent('Live Chat Ticket Submitted', eventData)
    } else {
      TrackEvent(`Live Chat Form ${data.type} Submitted`, eventData)
    }
  }
  return (
    <>
      <div className=" flex flex-row justify-between gap-4 h-12 text-white px-4 w-full max-w-[1076px] mx-auto">
        <Link
          to={'/'}
          className={step === 3 && cartStatus === CHECKOUT_STATUS_FAILED ? 'hidden sm:block ' : ' '}
        >
          <GnsLogo
            alt="Pre-owned Watches and Luxury Jewelry by Gray and Sons"
            className="h-[3.43rem] w-[9rem] duration-200 "
          />
        </Link>
        <div
          className={
            (step === 3 && cartStatus === CHECKOUT_STATUS_FAILED
              ? 'translate-y-0 flex '
              : 'hidden translate-y-10 ') +
            'duration-300 h-12 ease-in-out w-full text-sm  flex-row  justify-end items-center'
          }
        >
          <p className="text-sm xxs:max-w-max text-white uppercase xxs:text-xl bg-red-700 h-12 flex items-center px-4 w-full ">
            Need Help?
          </p>
          <div className="w-full sm:w-auto flex flex-row pl-4 space-x-8 justify-end items-center ">
            <a className="text-black hover:text-red-600" href="mailto:info@grayandsons.com">
              <div className="flex flex-row items-center justify-center h-12 aspect-square md:aspect-auto ">
                <IconMailDL className="h-9 w-9" />
                <p className="hidden md:flex">&nbsp; E-Mail Us</p>
              </div>
            </a>
            <button className="text-black hover:text-red-600 group" onClick={openChat}>
              <div className="flex flex-row items-center justify-center relative ">
                <div className="flex flex-row gap-[.05rem] absolute top-3 left-[.25rem] z-20">
                  <div className="group-hover:bg-red-600 h-1 w-1 rounded-full group-hover:chat-dot-blink1 "></div>
                  <div className="group-hover:bg-red-600 h-1 w-1 rounded-full group-hover:chat-dot-blink2"></div>
                  <div className="group-hover:bg-red-600 h-1 w-1 rounded-full group-hover:chat-dot-blink3"></div>
                </div>
                <IconChatDL />
                <p className="hidden md:flex">&nbsp; Live Chat</p>
              </div>
            </button>
            <a className="text-black hover:text-red-600" href="tel:+13058650999">
              <div className="flex flex-row items-center justify-center">
                <IconPhoneDL />
                <p className="hidden md:flex">&nbsp; 305.865.0999</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      {chat && (
        <LiveChatWidget
          license={process.env.GATSBY_LIVECHAT_KEY}
          chatBetweenGroups={false}
          visibility={'maximized'}
          group={'3'}
          onFormSubmitted={onFormSubmitted} />
      )}
    </>
  )
}
CheckOutHeader.propTypes = { step: PropTypes.number }

export default CheckOutHeader
